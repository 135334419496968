import React from 'react';
// import React, { useContext, useEffect } from 'react';
// import { AudioPlayerContext } from '../contexts/AudioPlayerState';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import BtnPlay from '../components/btn-play';
import EpisodesList from '../components/episodes-list';
import Disclaimer from '../components/Disclaimer';
import SEO from '../components/seo/seo';

/**
 * Episode Detail Page template
 * @param {object} data Episode, show, recent episodes data from GraphQL
 */
export default function EpisodePageMDTemplate({ data }) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  const podcast = data.showsMetaJson;
  const recentEpisodes = data.allMarkdownRemark;

  // Some episode summaries are missing a period or other punctuation at the end. This adds a period of that's the case.
  const terminalPunctuation = '.!?';
  let summaryText =
    frontmatter.summary !== 'undefined' ? frontmatter.summary : undefined;
  if (summaryText && terminalPunctuation.indexOf(summaryText.slice(-1)) < 0) {
    summaryText += '.';
  }
  const summary = summaryText ? summaryText : undefined;

  // <pre>
  //   { JSON.stringify(data, null, 2) }
  // </pre>

  /**
   * Set audio player default if no MP3 set already
   */
  // const useEffectOnlyOnce = (func) => useEffect(func, []);
  // const { changeAudioPlayerSrc } = useContext(AudioPlayerContext);
  // const contextAudio = useContext(AudioPlayerContext);
  // useEffectOnlyOnce(() => {
  //   console.log(contextAudio.srcUrl);

  //   if (contextAudio.srcUrl === '/b.mp3') {
  //     changeAudioPlayerSrc(frontmatter.url, frontmatter.title);
  //   }
  // });

  /**
   * Create episode's description that displays in header
   * @param {string} summary Episode description lain text or text mixed with HTML
   * @returns Description text formatted with or without HTML as appropriate
   */
  function renderEpisodeDescription(summary) {
    if (!summary.includes('<p>')) {
      return `<p class="episode__description">${summary}</p>`;
    } else {
      return summary;
    }
  }

  return (
    <>
      <SEO
        title={`${frontmatter.title} [${podcast.title}]`}
        description={summary || ''}
      />

      {/* ======================= */}
      {/* ==== START EPISODE ==== */}
      {/* ======================= */}
      <div className="newepWrap">
        <article className="episodeDetails">
          {/* :::: Header :::: */}
          <header className="episodeDetails__header">
            <h1 className="episodeDetails__title">{frontmatter.title}</h1>

            <p className="search__date-show">
              <time className="episodes__date">{frontmatter.pubDate}</time>
              <span className="episodes__show">
                <cite>
                  <Link to={`/podcasts/${podcast.id}/`}>{podcast.title}</Link>
                </cite>
              </span>
            </p>

            {summary && (
              <div
                dangerouslySetInnerHTML={{
                  __html: renderEpisodeDescription(summary),
                }}
                className="episode__description"
              />
            )}

            <BtnPlay
              showTitle={podcast.title}
              episodeTitle={frontmatter.title}
              episodeUrl={frontmatter.url}
              customClasses="episode__btn"
            />
          </header>

          {/* :::: Show notes :::: */}
          {html !== '<p>undefined</p>' && (
            <div
              dangerouslySetInnerHTML={{ __html: html }}
              className="showEpisodes episode__notes"
            />
          )}
        </article>
        {/* end episode */}

        {/* ======================= */}
        {/* ==== START SIDEBAR ==== */}
        {/* ======================= */}
        <aside className="episodeSidebar sidebar">
          <Link to={`/podcasts/${podcast.id}/`} className="showIntro__logoWrap">
            <Img
              fluid={podcast.logo.childImageSharp.fluid}
              alt={podcast.title}
              className="showIntro__logo"
            />
          </Link>

          <div className="sidebar__inner">
            <h1 className="show__heading">
              Recent Episodes{' '}
              <span className="visuallyHidden">from {podcast.title}</span>
            </h1>

            <EpisodesList
              episodes={recentEpisodes}
              showPlayBtn={false}
              showSummary={false}
              showsMeta={podcast}
              classChildren="episodes__item--recent"
            />

            <p className="episodes__view-all">
              <Link to={`/podcasts/${podcast.id}/`}>
                View All <cite className="visuallyHidden">{podcast.title}</cite>{' '}
                Episodes
              </Link>
            </p>

            {/* Visit site + Legal */}
            <hr />
            <h1 className="show__heading">The Show</h1>
            <p>
              View the <Link to={`/podcasts/${podcast.id}/`}>show’s page</Link>{' '}
              on Dev Shows.
            </p>
            <p>
              Visit the {''}
              <a href={podcast.link} rel="external">
                show’s official site
              </a>
              .
            </p>

            <Disclaimer showTitle={podcast.title} showType="podcast" />
          </div>
        </aside>
        {/* end sidebar */}
      </div>
    </>
  );
}

export const EpisodePageQuery = graphql`
  query ($url: String!, $showId: String!) {
    markdownRemark(frontmatter: { url: { eq: $url } }) {
      frontmatter {
        title
        pubDate(formatString: "MMMM D, YYYY")
        showId
        summary
        url
      }
      html
    }

    showsMetaJson(id: { eq: $showId }) {
      title
      id
      link
      ...Logo
    }

    allMarkdownRemark(
      filter: { frontmatter: { showId: { eq: $showId } } }
      limit: 4
      sort: { order: DESC, fields: frontmatter___pubDate }
    ) {
      edges {
        node {
          frontmatter {
            title
            showId
            pubDate(formatString: "MMMM D, YYYY")
            summary
          }
          id
        }
      }
    }
  }
`;

// SCRAPS
// -------------------------

// {/* :::: Disclaimer :::: */}
// {html !== '<p>undefined</p>' && (
//   <p className="episode__disclaimer">
//     <small>
//       <strong>Please note:</strong> some of the links below may be
//       affiliate links that benefit <cite>{podcast.title}</cite>. Dev
//       Tools does not benefit from these affiliate links.
//     </small>
//   </p>
// )}

// <p className="episode__info episodes__info">
// <time
//   dateTime={`${frontmatter.pubDate}`}
//   className="episodes__date"
// >
//   {frontmatter.pubDate}
// </time>
// </p>
