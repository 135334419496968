import React from 'react';

const Disclaimer = ({ showTitle, showType }) => (
  <p>
    <small>
      {showTitle.substring(0, 4) !== 'The ' && `The`} <cite>{showTitle}</cite>{' '}
      content (logo and other images, audio, and text) is property of the{' '}
      {showType}, not <cite>Dev Shows</cite>, which isn’t affiliated. And, of
      course, any opinions expressed, or product endorsements made, by a show’s
      host(s) or guest(s) are theirs alone.
    </small>
  </p>
);

export default Disclaimer;
